<template >
    <div class="text-center">
        <v-dialog v-model="dialog" width="550">
            <template v-slot:activator="{ on, attrs }">
                <v-btn small elevation="0" color="success" dark v-bind="attrs" v-on="on">
                    <v-icon left small>mdi-account-plus</v-icon>
                    Cadastrar
                </v-btn>
            </template>

            <v-card>
                <v-card-title />
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <div v-if="loading_paciente">
                            <v-progress-circular indeterminate size="50" color="success"></v-progress-circular>
                        </div>
                        <div v-else>
                            <div>
                                <v-row>
                                    <span class="text-h5 ml-1 mb-3 pb-2"><b> Dados Pessoais </b></span>
                                </v-row>
                                <v-row dense>
                                    <v-text-field class="mb-2" dense outlined v-model="paciente.nm_paciente"
                                        :rules="[v => !!v || 'Campo obrigatório']" hide-details label="Nome"
                                        placeholder="Insira o nome completo do(a) paciente" required>
                                    </v-text-field>
                                </v-row>
                                <v-row dense align="center" justify="center" class="mb-3">
                                    <v-col dense cols="3" class="ma-0 pa-0">
                                        <v-autocomplete v-model="paciente.tp_sexo" label="Sexo" :items="sexo_items"
                                            item-value="value" required :rules="[v => !!v]" item-text="label"
                                            hide-details outlined dense />
                                    </v-col>
                                    <v-col cols="5" dense class="ma-0 pa-0">
                                        <v-text-field dense outlined v-mask="'###.###.###-##'" v-model="paciente.nr_cpf"
                                            label="CPF" hide-details>
                                        </v-text-field>
                                    </v-col>
                                    <v-col dense cols="4" class="ma-0 pa-0">
                                        <v-text-field dense hide-details outlined label="Data de Nascimento"
                                            v-model="born_date" type="date"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col dense>

                                        <v-autocomplete v-model="paciente.cd_multi_empresa" label="Empresa"
                                            :items="empresas" item-value="cd_multi_empresa" :loading="loading_empresas"
                                            :disabled="loading_empresas" item-text="ds_multi_empresa" hide-details
                                            outlined dense>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>

                            </div>
                            <v-divider class="my-4" />
                            <div>
                                <v-row>
                                    <span class="text-h5 ml-1 mb-3 pb-2"><b> Contato </b></span>
                                </v-row>
                                <v-row dense>
                                    <v-col>

                                        <v-text-field dense outlined hide-details v-model="paciente.email"
                                            label="E-mail" />
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="4">
                                        <v-text-field :rules="[v => !!v && v.length === 15]" dense hide-details outlined
                                            v-mask="'(##) #####-####'" v-model="celular" label="Celular" required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field dense hide-details outlined v-mask="'(##) #####-####'"
                                            v-model="fone" label="Telefone" required :rules="[e=>!!e||'Obrigatório']">
                                        </v-text-field>
                                    </v-col>
                                    <v-col dense cols="4">
                                        <v-text-field dense hide-details outlined v-mask="'(##) #####-####'"
                                            v-model="comercial" label="Comercial"></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                           
                            <v-divider class="my-4" />
                            <v-row dense>
                                <v-col>
                                    <v-btn block color="warning" class="mr-2" @click="limparForm"> Limpar
                                    </v-btn>
                                </v-col>
                                <v-col v-if="valid">
                                    <confirm-dialog block v-if="valid" openBtnTextDialog="Cadastrar"
                                        @confirmation="confirmationDialog" />
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AppEtlService from '@/service/AppEtlService'
import ConfirmDialog from '@/components/confirmDialog'
const api = new AppEtlService()
export default {
    name: 'AddPaciente',
    components: {
        ConfirmDialog
    },
    data() {
        return {
            empresas: [],
            sexo_items: [
                { value: 'M', label: 'Masculino' },
                { value: 'F', label: 'Feminino' }
            ],
            loading_empresas: false,
            loading_paciente: false,
            dialog: false,
            paciente: {
                cd_cidade: null,
                nm_paciente: null,
                tp_sexo: null,
                ds_endereco: null,
                dt_nascimento: null,
                nm_mae: null,
                nr_cep: null,
                nm_bairro: null,
                nr_cpf: null,
                nr_identidade: null,
                ds_om_identidade: null,
                email: null,
                cd_multi_empresa: 1,
                nr_ddd_celular: null,
                nr_celular: null,
                nr_ddd_fone: null,
                nr_fone: null,
                nr_ddd_comercial: null,
                nr_comercial: null
            },
            fone: '',
            celular: '',
            comercial: '',
            valid: true,
            // datepicker dt_nascimento
            born_date: null,
            datepicker_dt_nascimento_dialog: false,

        }
    },
    methods: {
        numNotEmpty(value) {
            return value?.length === 15
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        idade() {

            var nascimento = new Date(this.born_date)
            var hoje = new Date()
            var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear()

            if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
                new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))
                diferencaAnos--;
            return diferencaAnos
        },
        async confirmationDialog(event) {
            if (event && this.paciente.nr_cpf) {
                const cpf = this.paciente.nr_cpf.replace(/\D/g, '')
                const res = await api.get_query('get_paciente', ['%', '%', cpf])

                if (res.data.length > 0) {
                    alert('Já existe um paciente com o CPF informado')
                    return
                }

                this.paciente.nr_cpf = cpf
            }
            this.createPaciente()
        },
         createPaciente() {
            if (this.$refs.form.validate()) {
                return api.create_etl('etl/query-post/?cod_query=post_paciente', Object.values(this.paciente))
                .then((response) => {
                    this.limparForm()
                    this.$emit('onCreatedPaciente', { nr_cpf: this.paciente.nr_cpf, data: response.data })
                    this.dialog = false  
                })
                .catch(error => {
                    console.error(error.response)
                })
            }
        },
        limparForm() {
            this.paciente.nm_paciente = null
            this.paciente.dt_nascimento = null
            this.paciente.tp_sexo = null
            this.paciente.nr_celular = null
            this.paciente.email = null
            this.paciente.cd_multi_empresa = null
            this.paciente.nr_cpf = null
            this.born_date = null
            this.fone = null
            this.celular = null
            this.comercial = null
        },
        getEmpresas(url, empresas) {
            this.loading_empresas = true
            api.get_query(url, empresas)
            .then((response) => {
                this.empresas = response.data
            })
            .catch(error => { 
                console.log(error)
            })
            .finally(()=>{
                this.loading_empresas = false
            })
        },
    },
    mounted() {
        let u = JSON.parse(localStorage.getItem('cfgu'))
        this.empresasmv = u.multi_empresas
        for (let i = 0; i < 3; i++) {
            this.empresasmv[i] ??= ""
        }
        this.getEmpresas('get_empresas', this.empresasmv)
    },
    computed: {
        computedDataNascimento() {
            return this.formatDate(this.born_date)
        },

    },
    watch: {
        born_date(val) {
            this.paciente.dt_nascimento = val
        },
        celular(val) {
            if (val?.length === 15) {
                const [, ddd, num] = val.replace(/\D/g, '').match(/(\d{2})(\d{9})/)
                this.paciente.nr_celular = num
                this.paciente.nr_ddd_celular = ddd

            }
        },
        fone(val) {
            if (val?.length === 15) {
                const [, ddd, num] = val.replace(/\D/g, '').match(/(\d{2})(\d{9})/)
                this.paciente.nr_fone = num
                this.paciente.nr_ddd_fone = ddd
            }
        },
        comercial(val) {
            if (val?.length === 15) {
                const [, ddd, num] = val.replace(/\D/g, '').match(/(\d{2})(\d{9})/)
                this.paciente.nr_comercial = num
                this.paciente.nr_ddd_comercial = ddd
            }
        },
    }
}
</script>