<template>
    <v-dialog v-model="dialog" persistent max-width="290">
        <template v-slot:activator="{ on }">
            <v-btn block :color="openBtnColorDialog" dark v-on="on" dense >  {{openBtnTextDialog}} </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5"> {{title}} </v-card-title>
            <v-card-text>
                {{text}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="confirm(false)" >
                    {{cancelText}}
                </v-btn>
                <v-btn color="green darken-1" text @click="confirm(true)" >
                    {{confirmText}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
  export default {
    props:{
        text:{
            default:'Desejar confirmar os dados?',
            type: String
        },
        title:{
            default:'',
            type: String
        },
        confirmText:{
            default:'Sim'
        },
        cancelText:{
            default:'Não'
        },
        openBtnTextDialog:{
            default:'Confirmar'
        },
        openBtnColorDialog:{
            default:'success'
        }
    },
    data () {
      return {
        dialog: false,
      }
    },
    methods:{
        confirm(value){
            this.$emit('confirmation', value)
            this.dialog = false
        }
    }
  }
</script>